<template>
    <div style="-webkit-overflow-scrolling: touch">
        <van-grid :gutter="10" :border="false" :column-num="3" class="video-list">
            <van-grid-item  v-for="item in certificateType" :key="item.id" :item="item"  :to="{name: 'certificateList', params: { certificateId: item.certificateListArr }}" >
                <van-image :src="require('../../../assets/img/video/certificate.png')" />
                <span>{{item.idTypeName}}</span>
            </van-grid-item>
            <!-- <van-grid-item to="/videoList">
                <van-image src="https://img01.yzcdn.cn/vant/apple-2.jpg" />

                <span>钢筋扫描仪</span>
            </van-grid-item>
            <van-grid-item to="/videoList">
                <van-image src="https://img01.yzcdn.cn/vant/apple-3.jpg" />
                <span>钢筋扫描仪</span>
            </van-grid-item> -->
        </van-grid>
    </div>
</template>
<script>
        //     {
		// 	"idTypeName" : "鉴定证书",
		// 	"grade" : "2",
		// 	"children" : [{
		// 		"idTypeName" : "RTK",
        //      "RTK|require('../../assets/img/video/multiple/jdzs/rtk/3e2579d1d80a72fbbd7ea3dfc6fc22b.jpg'),RTK2|require('../../assets/img/video/multiple/jdzs/rtk/d06bf69d1f9763fd73552f63e3a5161.jpg'),RTK2|require('../../assets/img/video/multiple/jdzs/rtk/fb6c025b2f63b197c70933b7ade16d2.jpg'),RTK2|require('../../assets/img/video/multiple/jdzs/rtk/fda78656c6b0486a76aa75ae60828bf.jpg')"
		// 		"children" : [{
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/rtk/3e2579d1d80a72fbbd7ea3dfc6fc22b.jpg"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/rtk/d06bf69d1f9763fd73552f63e3a5161.jpg"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/rtk/fb6c025b2f63b197c70933b7ade16d2.jpg"
		// 		}, {
		// 			"imgUrl" : ""
		// 		}]
		// 	}, {
		// 		"idTypeName" : "垂准仪",
		// 		"children" : [{
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}]
		//     "垂准仪|/jdzs/czy/1549a4f97f4e7687c46a2e8f1bed5e2.jpg,垂准仪|/jdzs/czy/56209c04f937f108bbb8d8fe35f888f.jpg,垂准仪|/jdzs/czy/b545faeb9f8cdd3960488b948973a20.jpg"
		// 	}, {
		// 		"idTypeName" : "钢筋扫描仪",
		// 		"children" : [{
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}]
		//  "钢筋扫描仪|/jdzs/gjsmy/1.jpg,钢筋扫描仪|/jdzs/gjsmy/1.jpg"
		// 	}, {
		// 		"idTypeName" : "工程检测尺",
		// 		"children" : [{
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}]
		//      "工程检测尺|/jdzs/gcjcc/6fd34035d5e882ca2d745154ce4107c.jpg,工程检测尺|/jdzs/gcjcc/5718af77081fa77b61155ed0445af5c.jpg"
		// 	}, {
		// 		"idTypeName" : "经纬仪",
		// 		"children" : [{
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}]
		//      "经纬仪|/jdzs/jwy/a8c9f4d78e7480721cc2b038db9e6dc.jpg,经纬仪|/jdzs/jwy/d8ddc445fd360a8882e08de2ba4ea3d.jpg,经纬仪|/jdzs/jwy/e9e8b04b847403fab16f4dce841796d.jpg"
		// 	}, {
		// 		"idTypeName" : "卷尺50米",
		// 		"children" : [{
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}]
		//      "卷尺50米|/jdzs/jc50m/1.jpg,卷尺50米|/jdzs/jc50m/2.jpg"
		// 	}, {
		// 		"idTypeName" : "徕卡原版报告",
		// 		"children" : [{
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/lkybbg/1.jpg"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/lkybbg/2.jpg"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/lkybbg/3.jpg"
		// 		}]
		//     "徕卡原版报告|/jdzs/lkybbg/1.jpg,徕卡原版报告|/jdzs/lkybbg/2.jpg,徕卡原版报告|/jdzs/lkybbg/3.jpg"
		// 	}, {
		// 		"idTypeName" : "扭矩扳子",
		// 		"children" : [{
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}]
		//    "扭矩扳子|/jdzs/njbz/1.jpg,扭矩扳子|/jdzs/njbz/2.jpg,扭矩扳子|/jdzs/njbz/3.jpg"
		// 	}, {
		// 		"idTypeName" : "全站仪",
		// 		"children" : [{
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/qzy/1.jpg"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/qzy/2.jpg"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/qzy/3.jpg"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/qzy/4.jpg"
		// 		}]
		//      "全站仪|1.jpg,全站仪|2.jpg,全站仪|3.jpg,全站仪|4.jpg"
		// 	}, {
		// 		"idTypeName" : "水准仪",
		// 		"children" : [{
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/szy/1.jpg"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/szy/2.jpg"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple/jdzs/szy/3.jpg"
		// 		}]
		//      "水准仪|/jdzs/szy/1.jpg,水准仪|/jdzs/szy/2.jpg,水准仪|/jdzs/szy/3.jpg"
		// 	}, {
		// 		"idTypeName" : "塔尺",
		// 		"children" : [{
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}, {
		// 			"imgUrl" : "../../assets/img/video/multiple"
		// 		}]
		//      "水准仪|/jdzs/tc/1.jpg,水准仪|/jdzs/tc/2.jpg"
		// 	}]
		// },
    export default {
        data() {
            return {
                certificateType: [{
                    "idType": 1,
                    "idTypeName" : "RTK",
                    "certificateListArr":"GNSS接收机检定证书第1页|/jdzs/rtk/fb6c025b2f63b197c70933b7ade16d2.jpg,GNSS接收机检定证书第2页|/jdzs/rtk/fda78656c6b0486a76aa75ae60828bf.jpg,GNSS接收机检定证书第3页|/jdzs/rtk/3e2579d1d80a72fbbd7ea3dfc6fc22b.jpg,GNSS接收机检定证书第4页|/jdzs/rtk/d06bf69d1f9763fd73552f63e3a5161.jpg"
                }, {
                    "idType": 2,
                    "idTypeName" : "垂准仪",
					"certificateListArr": "激光垂准仪校准证书第1页|/jdzs/czy/b545faeb9f8cdd3960488b948973a20.jpg,激光垂准仪校准证书第2页|/jdzs/czy/56209c04f937f108bbb8d8fe35f888f.jpg,激光垂准仪校准证书第3页|/jdzs/czy/1549a4f97f4e7687c46a2e8f1bed5e2.jpg"
                }, {
                    "idType": 3,
                    "idTypeName" : "钢筋扫描仪",
					"certificateListArr": "钢筋位置测定仪校准证书第1页|/jdzs/gjsmy/1.jpg,钢筋位置测定仪校准证书第2页|/jdzs/gjsmy/2.jpg"
                }, {
                    "idType": 4,
                    "idTypeName" : "工程检测尺",
					"certificateListArr":  "建筑工程质量检测器测试报告第1页|/jdzs/gcjcc/5718af77081fa77b61155ed0445af5c.jpg,建筑工程质量检测器测试报告第2页|/jdzs/gcjcc/6fd34035d5e882ca2d745154ce4107c.jpg"
                }, {
                    "idType": 5,
                    "idTypeName" : "经纬仪",
					"certificateListArr":  "电子经纬仪检定证书第1页|/jdzs/jwy/e9e8b04b847403fab16f4dce841796d.jpg,电子经纬仪检定证书第2页|/jdzs/jwy/a8c9f4d78e7480721cc2b038db9e6dc.jpg,电子经纬仪检定证书第3页|/jdzs/jwy/d8ddc445fd360a8882e08de2ba4ea3d.jpg"
                }, {
                    "idType": 6,
                    "idTypeName" : "卷尺50米",
					"certificateListArr": "钢卷尺检定证书第1页|/jdzs/jc50m/1.jpg,钢卷尺检定证书第2页|/jdzs/jc50m/2.jpg"
                }, {
                    "idType": 7,
                    "idTypeName" : "徕卡原版报告",
					"certificateListArr":  "全站仪检定证书第1页|/jdzs/lkybbg/1.jpg,全站仪检定证书第2页|/jdzs/lkybbg/2.jpg,全站仪检定证书第3页|/jdzs/lkybbg/3.jpg"
                }, {
                    "idType": 8,
                    "idTypeName" : "扭矩扳子",
					"certificateListArr": "扭矩扳子校准证书第1页|/jdzs/njbz/1.jpg,扭矩扳子校准证书第2页|/jdzs/njbz/2.jpg,扭矩扳子校准证书第3页|/jdzs/njbz/3.jpg"
                }, {
                    "idType": 9,
                    "idTypeName" : "全站仪",
					"certificateListArr": "全站仪检定证书第1页|/jdzs/qzy/1.jpg,全站仪检定证书第2页|/jdzs/qzy/2.jpg,全站仪检定证书第3页|/jdzs/qzy/3.jpg,全站仪检定证书第4页|/jdzs/qzy/4.jpg"
                    
                }, {
                    "idType":10,
                    "idTypeName" : "水准仪",
					"certificateListArr":  "水准仪检定证书第1页|/jdzs/szy/1.jpg,水准仪检定证书第2页|/jdzs/szy/2.jpg,水准仪检定证书第3页|/jdzs/szy/3.jpg"
                }, {
                     "idType":11,
                    "idTypeName" : "塔尺",
					"certificateListArr": "塔尺测试报告第1页|/jdzs/tc/1.jpg,塔尺测试报告第2页|/jdzs/tc/2.jpg"
                }]
            };
        }
    }
</script>
<style lang="less" scoped>
    .video-list span {
        font-size: 26px;
        margin-top: 20px;
        height: 76px;
    }
    .van-grid-item /deep/ .van-grid-item__content {
        padding-bottom: 0;
		border-radius: 10px!important;
    }
</style>